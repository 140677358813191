// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-showcase-js": () => import("./../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-commonfeature-js": () => import("./../src/templates/commonfeature.js" /* webpackChunkName: "component---src-templates-commonfeature-js" */),
  "component---src-templates-feature-js": () => import("./../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-package-js": () => import("./../src/templates/package.js" /* webpackChunkName: "component---src-templates-package-js" */)
}

